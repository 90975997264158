export const convertDateToString = (input: string) => {
  const date = new Date(input);
  const result =
    ("0" + date.getDate()).slice(-2) +
    "/" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "/" +
    date.getFullYear();
  return result;
};
